/* eslint-disable max-len */
import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Contact from "../components/template-parts/Contact";

const ContactPage = () => (
  <Layout>
    <SEO
      title="Contato"
      keywords="isolamento social, telemedicina, medicina chinesa, tratamento da dor, clínica geral, covid-19, nutrição, mudança de hábitos"
      description="Dra Helena Campiglia - Consultório médico em São Paulo - Rua Simão Álvares, 941 - Pinheiros - São Paulo-SP"
    />
    <Contact />
  </Layout>
);

export default ContactPage;
